import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Container } from "../layouts/components/Container"
import Logo from "../assets/icons/qprem-logo.svg"
import { useI18next } from "gatsby-plugin-react-i18next"
import { themeOptions, bp } from "../layouts/Styles"
import Button from "./Button"
import SubMenu from "./SubMenu"
import LanguagSwitch from "./LanguagSwitch"
import useBreakpoint from "../utils/use-breakpoint"
import BurgerMenuIcon from "./BurgerMenuIcon"
import MobileSubMenu from "./MobileSubMenu"
import { MenuContext, PreventBodyScroll } from "../layouts"
import FadeLink from "./FadeLink"
import { TFunction } from "i18next"
import ContactModal from "./ContactModal"
import { ArrowIcon } from "../utils/icons"

interface NavigationItemsType {
  text: string
  pages?: {
    title: string
    text: string
    link: string
    order: number
  }[]
}

const navigationItems: Array<NavigationItemsType> = [
  {
    text: "Lösungen",
    pages: [
      {
        title: "Alle Lösungen",
        text: "Alles aus einer Hand: Wir liefern modulare und individuelle Softwarelösungen für Ihren digitalen Betrieb",
        link: "/loesungen",
        order: 1,
      },
      {
        title: "Smart Office",
        text: "Individuell und effizient:\nUnser intelligentes System zur Gestaltung moderner Arbeitsumgebungen",
        link: "/loesungen/smart-office",
        order: 5,
      },

      {
        title: "E-Mobility",
        text: "Zukunftssicher unterwegs – mit unserer 360-Grad-Lösung für Elektromobilität",
        link: "/loesungen/e-mobility",
        order: 2,
      },
      {
        title: "Maps",
        text: "Wissen, wo’s langgeht:\nSmarte Routenführung auf Ihrem Areal",
        link: "/loesungen/maps",
        order: 6,
      },

      {
        title: "Parking",
        text: "Effizient, flexibel, profitabel: Smartes Parkraummanagement für Ihr Unternehmen",
        link: "/loesungen/parking",
        order: 3,
      },
      {
        title: "Communications",
        text: "Schnell, effizient, intelligent:\nDas zentrale Kommunikationssystem für Ihr Unternehmen",
        link: "/loesungen/communications",
        order: 7,
      },

      {
        title: "Access",
        text: "Unsere intelligente Infrastruktur für digitale Einlass- und Zugangskontrolle",
        link: "/loesungen/access",
        order: 4,
      },
      {
        title: "Analytics",
        text: "Steuern Sie Ihr Areal effektiv\nauf Grundlage relevanter Datenauswertungen",
        link: "/loesungen/analytics",
        order: 8,
      },
    ],
  },
  {
    text: "Services",
    pages: [
      {
        title: "Services",
        text: "Wir begleiten Sie auf dem Weg der digitalen Transformation Ihrer Immobilie",
        link: "/services",
        order: 1,
      },
      {
        title: "Custom Service",
        text: "Wir entwickeln und projektieren\nLösungen – individuell und maßgeschneidert",
        link: "/services/custom-service",
        order: 2,
      },
      {
        title: "Managed Service",
        text: "Rundum sorglos: Wir betreuen Sie vollumfänglich auf Ihrem Weg der digitalen Transformation",
        link: "/services/managed-service",
        order: 3,
      },
    ],
  },
  {
    text: "Unternehmen",
    pages: [
      {
        title: "Unternehmen",
        text: "Innovation und Leidenschaft",
        link: "/unternehmen",
        order: 1,
      },
      {
        title: "Team",
        text: "Die Gesichter hinter qprem",
        link: "/unternehmen/team",
        order: 2,
      },
      {
        title: "Blog",
        text: "Artikel zu den neuesten Trends und Entwicklungen",
        link: "/unternehmen/blog",
        order: 3,
      },
      {
        title: "Newsletter",
        text: "Unsere aktuellsten Innovationen individuell für Sie aufbereitet",
        link: "/unternehmen/newsletter",
        order: 4,
      },
    ],
  },
]

const Navigation: React.FC = () => {
  const { language, t } = useI18next()
  const [current, setCurrent] = useState<number | string>()
  const [fromElement, setFromElement] =
    useState<React.MutableRefObject<HTMLElement | null>>()
  const [showModal, setShowModal] = useState(false)
  const [isOpen, setOpenState] = useState(false)

  const isMobile = useBreakpoint()

  const menuContext = useContext(MenuContext)

  useEffect(() => {
    menuContext.registerMenuClose(() => {
      if (isOpen) {
        setOpenState(false)
        setCurrent(undefined)
      }
    })
  }, [menuContext])

  return (
    <>
      <NavigationContainer
        className="header"
        isOpen={current !== undefined}
        {...{ isMobile }}
      >
        <Container>
          <NavContainer>
            {isMobile ? (
              <MobileNavigation
                onBurgerMenuClick={() => setOpenState(prev => !prev)}
                {...{ isOpen, t }}
              />
            ) : (
              <DesktopNavigation
                {...{ language, t, current, fromElement }}
                openModal={() => setShowModal(true)}
                setCurrent={item => setCurrent(item)}
              />
            )}
          </NavContainer>
        </Container>

        {(isOpen || current !== undefined) && <PreventBodyScroll />}

        {isMobile && (
          <MobileNavFlyout {...{ isOpen }}>
            {navigationItems.map((item, idx) => (
              <NavItem
                key={idx}
                onClick={() =>
                  setCurrent(prev => (prev === idx ? undefined : idx))
                }
              >
                <MobileNavLink current={current === idx}>
                  <p>{t(item.text)}</p>
                  {item.pages && <ArrowIcon />}
                </MobileNavLink>

                <MobileSubMenu isOpen={current === idx} pages={item.pages} />
              </NavItem>
            ))}

            <NavItem
              onClick={e => {
                setShowModal(true)
                setCurrent(undefined)
                setOpenState(false)
              }}
            >
              <MobileNavLink>{t("Kontakt aufnehmen")}</MobileNavLink>
            </NavItem>

            <NavItem
              onClick={() =>
                setCurrent(prev => (prev === "lng" ? undefined : "lng"))
              }
            >
              <MobileNavLink current={current === "lng"}>
                {language.toLocaleUpperCase()}
                <ArrowIcon />
              </MobileNavLink>

              {current === "lng" && <LanguagSwitch mobile />}
            </NavItem>
          </MobileNavFlyout>
        )}

        <ContactModal
          {...{ showModal, setShowModal }}
          setFromElement={ref => setFromElement(ref)}
        />
      </NavigationContainer>
      <Backdrop isOpen={current !== undefined} {...{ isMobile }} />
    </>
  )
}

const DesktopNavigation: React.FC<{
  current?: string | number
  t: TFunction<"translation", undefined, "translation">
  setCurrent: (item?: string | number) => void
  openModal: () => void
  fromElement?: React.MutableRefObject<HTMLElement | null>
  language: string
}> = ({ t, current, setCurrent, fromElement, openModal }) => {
  const [onLngHover, setOnLngHover] = useState(false)
  const { languages, changeLanguage, language } = useI18next()

  return (
    <>
      <Menu>
        <FadeLink to="/" title={t("Startseite") ?? ""}>
          <NavLogo className="logo" />
        </FadeLink>

        {navigationItems.map((item, idx) => (
          <NavItem
            key={idx}
            onMouseEnter={() => setCurrent(idx)}
            onMouseLeave={() => setCurrent(undefined)}
          >
            <NavLink current={current === idx}>
              <p>{t(item.text)}</p>
              {item.pages && <ArrowIcon />}
            </NavLink>

            {current === idx && (
              <SubMenu
                isOpen={current === idx}
                pages={item.pages}
                onClick={() => setCurrent(undefined)}
              />
            )}
          </NavItem>
        ))}
      </Menu>

      <Actions>
        <Button
          size="small"
          variant="primary"
          text={t("Kontakt aufnehmen")}
          onClick={e => {
            fromElement!!.current = e?.target as HTMLElement
            openModal()
          }}
        />

        <Language
          onMouseEnter={() => setOnLngHover(true)}
          onMouseLeave={() => setOnLngHover(false)}
          isHover={onLngHover}
        >
          <div className="language">
            <div title={t(language) ?? ""}> {language}</div>

            {languages.map(
              (lang, idx) =>
                language !== lang && (
                  <FadeLink
                    key={idx}
                    onClick={() => changeLanguage(lang)}
                    title={t(lang) ?? ""}
                  >
                    {lang}
                  </FadeLink>
                )
            )}
          </div>
        </Language>
      </Actions>
    </>
  )
}
const MobileNavigation: React.FC<{
  isOpen: boolean
  onBurgerMenuClick: () => void
  t: TFunction<"translation", undefined, "translation">
}> = ({ isOpen, onBurgerMenuClick, t }) => {
  return (
    <>
      <MobileMenu>
        <FadeLink to="/" title={t("Startseite") ?? ""}>
          <NavLogo className="logo" />
        </FadeLink>
      </MobileMenu>

      <BurgerMenuIcon {...{ isOpen }} onClick={() => onBurgerMenuClick()} />
    </>
  )
}

const Language = styled.div<{ isHover: boolean }>`
  height: 2rem;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 1.5rem;
  flex-direction: column;
  align-items: center;
  transition: color 200ms ease;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;

  .language {
    transition: ${themeOptions.transition};
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform: ${({ isHover }) =>
      isHover &&
      `translate3d(0px, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`};
    transform-style: preserve-3d;
  }
`

const Backdrop = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    `
    position: absolute;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    z-index: 0;
    opacity 0;
    transition: ${themeOptions.transition};
  `}

  ${({ isOpen, isMobile }) => !isMobile && isOpen && `z-index: 10; opacity 1;`}
`

const NavigationContainer = styled.div<{
  isOpen: boolean
  isMobile: boolean
}>`
  ${({ isMobile, theme }) =>
    !isMobile &&
    `
    transition: ${themeOptions.transition};
    background: ${theme.background.default};
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    position: relative;
    z-index: 1;
  `}

  ${({ isOpen, isMobile }) =>
    !isMobile && isOpen && `z-index: 20; box-shadow: none;`}
`

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: ${themeOptions.headerHeight};
`

const MobileNavFlyout = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 0px;
  transition: ${themeOptions.transition};
  position: absolute;
  opacity 0;
  z-index: 0;
  padding-top: 0;
  padding-bottom: 8rem;
  overflow: auto;
  
  ${({ isOpen, theme }) =>
    isOpen &&
    `
    z-index: 1;
    padding-top: ${themeOptions.distance};
    background: ${theme.background.default};
    height: 100%;
    opacity 1;
    
  `}
`

const NavItem = styled.div`
  position: relative;
`

const NavLink = styled.div<{ current: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: default;
  padding: 2rem 0;
  transition: ${themeOptions.transition};
  width: fit-content;

  ${props =>
    props.current &&
    `
    p {
      font-weight: 500;
    }
      & ${ArrowIcon} {
        transform: rotate(90deg);
        polyline {
          stroke-width: 12px !important;
        }
      }
  `}
`

const MobileNavLink = styled.div<{ current?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 2rem 0;
  transition: ${themeOptions.transition};
  font-size: 1.2rem;
  padding: ${themeOptions.distance};
  pointer-events: auto;
  outline: none;
  position: relative;

  ${props =>
    props.current &&
    `
    font-weight: 500;
    & ${ArrowIcon} {
      transform: rotate(90deg);
    }
  `}

  :after {
    position: absolute;
    background: linear-gradient(90deg, #00000010, transparent);
    top: 0;
    right: 0;
    bottom: 0;
    width: 0px;
    transition: all 0.5s;
    transition-property: width;
    content: "";
    pointer-events: none;
  }

  :active {
    background-color: rgba(0, 0, 0, 0.19);
    transition: all 0s ease 0s;

    :after {
      width: 100%;
      transition: all 0s;
    }
  }
`

const Menu = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  ${bp("mobile")`display: none;`};
  ${NavItem} {
    min-width: 13rem;
    padding-right: 0 2rem;
  }
`

const MobileMenu = styled.div`
  display: grid;
`

const NavLogo = styled(Logo)`
  fill: ${({ theme }) => theme.primary.main};
  width: 8rem;
  height: 100%;
`

const Actions = styled.div`
  gap: 2rem;
  display: flex;
  align-items: center;
  ${NavItem} {
    min-width: 2.5rem;
  }
  ${bp("mobile")`display: none;`};
`

export default Navigation

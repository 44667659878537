import { createGlobalStyle, css } from "styled-components"

export const colors = {
  primary: {
    main: "#55B464",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#ff6e00",
    light: "#ffb900",
  },
  error: {
    main: "#de1616",
  },
  background: {
    default: "#FFFFFF",
    paper: "#f2f2f2",
  },
  text: {
    main: "#505050",
    light: "#8C8C8C",
    linkHover: "#000000",
  },
}

export type Pallette = typeof palette.light

export const palette = {
  light: {
    primary: {
      main: "#55B464",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff6e00",
      light: "#ffb900",
    },
    error: {
      main: "#FF0000",
      contrastText: "#ffffff",
    },
    background: {
      default: "#FFFFFF",
      paper: "#f2f2f2",
    },
    text: {
      main: "#505050",
      light: "#8C8C8C",
      linkHover: "#000000",
    },
  },
  dark: {
    primary: {
      main: "#55B464",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff6e00",
      light: "#ffb900",
    },
    error: {
      main: "#ff4d4d",
      contrastText: "#ffffff",
    },
    background: {
      default: "#1e1e1e",
      paper: "#2c2c2c",
    },
    text: {
      main: "#e6e6e6",
      light: "#B1B1B1",
      linkHover: "#FFFFFF",
    },
  },
}

export const themeOptions = {
  pageWidth: "81.25rem",
  headerHeight: "5.625rem",
  distance: "1.5rem",
  sectionDistance: "8rem 0",
  radius: "0.6rem",
  transition: "all 0.2s ease-out",
}
export const devicesSizing = {
  xl: 2000,
  lg: 1536,
  md: 1024,
  sm: 720,
  xs: 320,
}

export const breakpoints = {
  hdpi: `screen and (min-width: ${devicesSizing.xl}px)`,
  mdpi: `screen and (max-width: ${devicesSizing.lg}px)`,
  mobile: `screen and (max-width: ${devicesSizing.md}px)`,
  mobile_mdpi: `screen and (max-width: ${devicesSizing.sm}px)`,
  mobile_ldpi: `screen and (max-width: ${devicesSizing.xs}px)`,
}

type Breakpoint = keyof typeof breakpoints

export const bp =
  (point: Breakpoint) =>
  (...args: any) =>
    css`
      @media ${breakpoints[point]} {
        ${(css as any)(...args)}
      }
    `

const Styles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    word-break: break-word;

  }

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1.618;
    white-space: pre-line;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    min-height: 100vh;
  }

  button, input {
    outline: none;
    border: none;
    background: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
  }

  html, body, * {
    font-family: 'Poppins', sans-serif;
    color: ${({ theme }) => (theme as any).text.main};
    font-weight: 300;
  }

  .main {
    min-height: 70vh;
  }

  a {
    text-decoration: none;
    color: unset;
    transition: ${themeOptions.transition};
  }
  
  html, body {
    background: ${({ theme }) => (theme as any).background.default};
    font-size: 16px;

    ${bp("mdpi")`font-size: 14px;`}
    ${bp("mobile")`font-size: 12px;`}

    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  h1 {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
  }
  
  h2 {
    font-size: 2.612rem;
    line-height: 1.3;
    font-weight: 600;
  }
  
  h3 {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 500;
  }

  strong {
    font-weight: 600;
  }

  a:hover {
    color: ${({ theme }) => (theme as any).text.linkHover};
  }
`

export default Styles

import { useState, useEffect } from "react"
import throttle from "lodash.throttle"
import { devicesSizing } from "../layouts/Styles"
import { isBrowser } from "./ssr"

const getDeviceConfig = (width: number) => {
  if (width < devicesSizing.md) return true
  return false
}

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(
    () => isBrowser && getDeviceConfig(window.innerWidth)
  )

  useEffect(() => {
    if (!isBrowser) return

    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200)
    window.addEventListener("resize", calcInnerWidth)
    return () => window.removeEventListener("resize", calcInnerWidth)
  }, [])

  return brkPnt
}
export default useBreakpoint

import React, { FormEvent, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Modal, { ModalHeader } from "../Modal"
import { bp } from "../../layouts/Styles"
import Checkbox from "../Checkbox"
import SendArrowSVG from "../../assets/icons/line-arrow.svg"
import ContactInput from "../ContactInput"
import FadeLink from "../FadeLink"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { event } from "../../utils/ssr"
import TextAlert from "./TextAlert"
import addToMailchimp from "gatsby-plugin-mailchimp"

interface ContactRequest {
  firstName: string
  lastName: string
  company: string
  email: string
  accept: boolean
  subscribe: boolean
}

interface ContactFormProps {
  hideClosingTag?: boolean
  onClose?: () => void
  onSent?: () => void
  onSubmit?: (req: ContactRequest) => void
  spacing?: Spacing
}

type Spacing = "compact" | "loose"

const ContactForm: React.FC<ContactFormProps> = ({
  onClose = () => {},
  onSent = () => {},
  hideClosingTag,
  spacing = "compact",
}) => {
  useEffect(() => {
    event("contact_form_opened", {
      page: window.location.pathname,
    })
  }, [])

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [accept, setAccept] = useState(false)
  const [subscribe, setSubscribe] = useState(false)

  const { t } = useTranslation()
  const [hasCheckError, setHasCheckError] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const validEmail = val.test(email)

    if (!validEmail || !accept) {
      event("contact_form_send_fail", {
        page: window.location.pathname,
        valid_email: validEmail ? "yes" : "no",
        privacy_notice_accepted: accept ? "yes" : "no",
      })

      setHasEmailError(!validEmail)
      setHasCheckError(!accept)
      return
    }

    const request = {
      email,
      message,
      signupNewsletter: false,
      fields: {
        Vorname: firstName,
        Nachname: lastName,
        Firma: company,
      },
    }

    setIsLoading(true)
    event("contact_form_send", {
      page: window.location.pathname,
    })

    try {
      await fetch("https://qprem-microsite-api.g51.dev/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      })

      {
        subscribe &&
          addToMailchimp(email, {
            FNAME: firstName,
            LNAME: lastName,
            COMPANY: company,
          })
      }

      onSent()
      setIsLoading(false)
      resetForm()
    } catch (e) {
      console.dir(e)
      onSent()
      setIsLoading(false)
      resetForm()
    }
  }

  const resetForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setCompany("")
    setMessage("")
  }

  return (
    <ContactFormContainer {...{ spacing }}>
      <ModalHeader
        className="header"
        title={t("Nutzen Sie die qprem Konnektivität.") ?? ""}
        {...{ hideClosingTag, onClose }}
      />

      <p>
        {t(
          "Mit einer breiten Palette an flexibel skalierbaren Lösungen, begleitet qprem Sie auf dem Weg der digitalen Transformation."
        )}
      </p>

      <form onSubmit={e => onSubmit(e)} noValidate>
        <ContactFormInputs {...{ spacing }}>
          <ContactInput
            placeholder={t("Vorname") ?? ""}
            onChange={s => setFirstName(s.target.value)}
            value={firstName}
            disabled={isLoading}
          />

          <ContactInput
            placeholder={t("Nachname") ?? ""}
            onChange={s => setLastName(s.target.value)}
            value={lastName}
            disabled={isLoading}
          />

          <ContactInput
            placeholder={t("Firma") ?? ""}
            onChange={s => setCompany(s.target.value)}
            value={company}
            disabled={isLoading}
          />

          <ContactInput
            placeholder={t("E-Mail") ?? ""}
            type="email"
            onChange={s => {
              setEmail(s.target.value)
              setHasEmailError(false)
            }}
            value={email}
            disabled={isLoading}
          />
        </ContactFormInputs>

        <MessageInput
          placeholder={t("Nachricht") ?? ""}
          {...{ spacing }}
          disabled={isLoading}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />

        {(hasEmailError || hasCheckError) && (
          <div className="errors">
            {hasCheckError && (
              <p className="error error-check">
                {t("Bitte akzeptieren Sie die")}{" "}
                <strong>{t("Datenschutzerklärung")}</strong>.
              </p>
            )}

            {hasEmailError && (
              <p className="error error-mail">
                {t("Bitte geben Sie eine gültige")}{" "}
                <strong>{t("E-Mail-Adresse")}</strong> {t("an.")}
              </p>
            )}
          </div>
        )}

        <Checkbox
          className="check"
          variant="secondary"
          onChange={c => {
            setAccept(c)
            setHasCheckError(false)
          }}
          disabled={isLoading}
          checked={accept}
        >
          {t("Ich akzeptiere die")}{" "}
          <FadeLink to="/datenschutz" onClick={() => onClose()}>
            {t("Datenschutzerklärung")}
          </FadeLink>
        </Checkbox>

        <Checkbox
          className="check"
          variant="secondary"
          onChange={c => setSubscribe(c)}
          disabled={isLoading}
          checked={subscribe}
        >
          {t("Bitte informieren Sie mich über neue Entwicklungen")}
        </Checkbox>

        <SendButton type="submit" aria-label="send" disabled={isLoading}>
          <span>{t("Senden")}</span>

          <div className="addon">
            <SendArrowSVG />
          </div>
        </SendButton>
      </form>
    </ContactFormContainer>
  )
}

const ContactFormContainer = styled.div<{ spacing: Spacing }>`
  padding: 2em 2.6em 3em 8.25em;

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      padding: 2em 0 3em 0;
    `}

  font-size: 1rem;
  width: 72em;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  *:disabled {
    opacity: 0.6;
    cursor: default;
  }

  textarea:disabled {
    background-color: ${({ theme }) => theme.background.default};
  }

  p {
    width: 41rem;
    max-width: 100%;
    line-height: 1.5;
    font-size: 1.3rem;
    margin-bottom: 1.5em;
    font-weight: 100;
  }

  p.error {
    font-size: 0.8em;
    margin-bottom: 0.2em;
    color: ${({ theme }) => theme.error.main};

    strong {
      color: ${({ theme }) => theme.error.main};
    }
  }

  div.errors {
    margin-bottom: 2em;
  }

  .check {
    margin-bottom: 1.25em;
    font-size: 0.8em;
  }

  form {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 90em;

      > div {
        margin: 0 2em 2em 0;
      }
    `}

  ${bp("mobile")`
    padding: 3em;

    p {
      font-size: 1.25rem;
    }

    .check {
      margin-bottom: 1.25em;
      font-size: 1em;
    }

    .header h3 {
      font-size: 1.7em;
      line-height: 1.5;
      padding-top: 0;
    }
  `}
`

const ContactFormInputs = styled.div<{ spacing: Spacing }>`
  display: flex;
  width: 52em;
  max-width: 100%;

  > div {
    width: calc(25% - 1em);
    margin: 0 1em 1em 0;
  }

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 90em;

      > div {
        margin: 0 2em 2em 0;
      }
    `}

  ${bp("mobile")`
    flex-direction: column;
    font-size: 1.2rem;

    > div {
      width: 100%;
      margin: 0 0 1em 0;
    }
  `}
`

const MessageInput = styled.textarea<{ spacing: Spacing }>`
  font-size: 0.9em;
  font-weight: 500;
  padding: 1.3em 1.1em 1.3em 3.2em;
  border: none;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.06);
  resize: none;
  width: 47.5em;
  height: 10em;
  border-radius: 10px;
  margin-bottom: 1.5em;
  transition: border 0.15s linear;
  border: 1px solid ${({ theme }) => theme.background.paper};
  outline: none;
  max-width: 100%;
  background: ${({ theme }) => theme.background.default};

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 70em;
      margin-bottom: 3em;
    `}

  :focus {
    border: 2px solid ${({ theme }) => theme.primary};
  }

  ::placeholder {
    opacity: 1;
  }

  ${bp("mobile")`
    width: 100%;
  `}
`

const SendButton = styled.button`
  font-size: 0.9em;
  display: flex;
  justify-items: space-between;
  align-items: stretch;
  margin-top: 0.85em;
  background-color: ${({ theme }) => theme.primary.main};
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.background.default};
    padding: 1rem 2rem;
  }

  div {
    padding: 0 2em;
    background-color: ${({ theme }) => theme.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 1.5em;
    polyline,
    line {
      stroke: ${({ theme }) => theme.background.default} !important;
    }
  }

  ${bp("mobile")`
    font-size: 1.2rem;
  `}
`

export const ThanksModal = ({ isShown = false, onClose = () => {} }) => {
  const { t } = useTranslation()

  return (
    <Modal {...{ onClose, isShown }} showBackdrop>
      <TextAlert {...{ onClose }} title={t("Vielen Dank!") ?? ""}>
        <p style={{ marginBottom: "0.4em" }}>
          {t("Ihre Kontaktanfrage wurde versendet.")}
        </p>
        <p>{t("Wir melden uns so bald wie möglich bei Ihnen zurück.")}</p>
      </TextAlert>
    </Modal>
  )
}

export default ContactForm

import Arrow from "../assets/icons/arrow.svg"
import styled from "styled-components"
import { themeOptions } from "../layouts/Styles"

export const ArrowIcon = styled(Arrow)`
  width: 0.7rem;
  transition: ${themeOptions.transition};
  polyline {
    stroke: ${({ theme }) => theme.text.main} !important;
  }
`

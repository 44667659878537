import React from "react"
import styled from "styled-components"
import LogoSVG from "../assets/icons/qprem-logo.svg"
import { bp, themeOptions } from "../layouts/Styles"
import { useTranslation } from "gatsby-plugin-react-i18next"
import LinkedInSVG from "../assets/icons/linked.svg"
import YoutubeSVG from "../assets/icons/youtube.svg"
import { Container } from "../layouts/components/Container"
import FadeLink from "./FadeLink"
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterContainer className="footer">
      <Contact t={(key: string) => t(key)} />

      <LinksColumn
        hideOnMobile={true}
        title={t("Lösungen")}
        path="/loesungen"
        links={[
          [t("E-Mobility"), "/loesungen/e-mobility"],
          [t("Parking"), "/loesungen/parking"],
          [t("Access"), "/loesungen/access"],
          [t("Smart Office"), "/loesungen/smart-office"],
          [t("Communications"), "/loesungen/communications"],
          [t("Maps"), "/loesungen/maps"],
          [t("Analytics"), "/loesungen/analytics"],
        ]}
      />

      <LinksColumn
        hideOnMobile={true}
        title={t("Services")}
        path="/services"
        links={[
          [t("Custom Service"), "/services/custom-service"],
          [t("Managed Service"), "/services/managed-service"],
        ]}
      />

      <LinksColumn
        hideOnMobile={false}
        title={t("Unternehmen")}
        path="/unternehmen"
        links={[
          [t("Team"), "/unternehmen/team"],
          [t("Blog"), "/unternehmen/blog"],
          [t("Newsletter"), "/unternehmen/newsletter"],
          ["", ""],
          [t("Impressum"), "/impressum"],
          [t("Datenschutz"), "/datenschutz"],
        ]}
      />
    </FooterContainer>
  )
}

const Contact: React.FC<{ t: (key: string) => string }> = ({ t }) => {
  return (
    <ContactContainer>
      <FadeLink to="/" title={t("Startseite")}>
        <LogoSVG className="logo" />
      </FadeLink>

      <AdressColumn
        title="Office Frankfurt"
        street="Daimlerstraße 32"
        city="60314 Frankfurt am Main"
      />

      <AdressColumn
        title="Office Hamburg"
        street="Elbchaussee 20"
        city="22765 Hamburg"
      />

      <div className="call-us">
        <a href="mailto:contact@qprem.de">{t("contact@qprem.de")}</a>
        <br />
        <a href="tel:+491622116582">+49 (0) 162 211 6582</a>
      </div>

      <SocialsRow />
    </ContactContainer>
  )
}

const LinksColumn: React.FC<{
  hideOnMobile: boolean
  title: string
  path: string
  links: [string, string][]
}> = ({ title, path, links, hideOnMobile }) => (
  <LinksContainer {...{ hideOnMobile }}>
    <div className="title">
      <FadeLink to={path} title={title}>
        {title}
      </FadeLink>
    </div>
    <ul>
      {links.map(([text, href], idx) =>
        text ? (
          <li key={idx}>
            <FadeLink to={href} title={text}>
              {text}
            </FadeLink>
          </li>
        ) : (
          <Spacing key={idx} />
        )
      )}
    </ul>
  </LinksContainer>
)

const AdressColumn: React.FC<{
  title: string
  street: string
  city: string
}> = ({ title, street, city }) => (
  <AdressContainer>
    <p className="title">{title}</p>
    <p>{street}</p>
    <p>{city}</p>
  </AdressContainer>
)

const SocialsRow: React.FC = () => (
  <SocialsContainer>
    <OutboundLink
      href="https://linkedin.com/company/74556574"
      target="_blank"
      aria-label="LinkedIn"
      title="LinkedIn"
      rel="nofollow"
    >
      <LinkedInSVG />
    </OutboundLink>

    <OutboundLink
      href="https://www.youtube.com/channel/UC0dXO2w2_Y6QKAuuyJ_r5zA"
      target="_blank"
      aria-label="YouTube"
      title="YouTube"
      rel="nofollow"
    >
      <YoutubeSVG />
    </OutboundLink>
  </SocialsContainer>
)

const AdressContainer = styled.div`
  padding-bottom: ${themeOptions.distance};

  .title {
    font-weight: 500;
  }
`

const FooterContainer = styled(Container)`
  display: flex;
  padding-top: 4rem;
  padding-bottom: 2rem;
  gap: ${themeOptions.distance};

  ${bp("mobile")`
    gap: 2rem;
    flex-direction: column;
  `}

  > div {
    width: 100%;

    > :first-child {
      height: 3.5rem;
      line-height: 1;
    }
  }
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;

  .logo path {
    fill: ${({ theme }) => theme.text.main};
  }
`

const LinksContainer = styled.div<{ hideOnMobile: boolean }>`
  padding-bottom: ${themeOptions.distance};

  .title a {
    font-weight: 500;
  }

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    bp("mobile")`
      display: none;
    `}
`

const SocialsContainer = styled.div`
  display: flex;
  padding-top: ${themeOptions.distance};
  > a {
    width: 30px;
    margin-right: 10px;
  }

  ${bp("mobile")`
    order: 4;
    padding: 0;
    padding-top: 2rem;

    > a {
      width: 35px;
    }
  `}
`

const Spacing = styled.div`
  padding-top: ${themeOptions.distance};
`

export default Footer

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"qprem | smart working environments.","description":"Die globale Softwareplattform zum digitalen Betrieb von Gewerbe- und Industrieimmobilien","short_name":"qprem","lang":"de","start_url":"/","background_color":"#ffffff","theme_color":"#55B464","display":"standalone","icon":"./src/assets/images/qprem-square.png","localize":[{"start_url":"/en/","lang":"en","name":"qprem | smart working environments.","short_name":"qprem","description":"The global software platform for the digital operation of commercial and industrial real estate"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cde8b69a0801e5aac62510030c04e7e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"redirect":false,"defaultLanguage":"de","siteUrl":"https://www.qprem.de","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-G2T2DHTMKF"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

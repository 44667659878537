import React, { InputHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { bp } from "../layouts/Styles"
import SendArrowSVG from "../assets/icons/line-arrow.svg"

const ContactInputWrapper = styled.div<{
  withSubmit?: boolean
  disabled?: boolean
}>`
  background-color: #00000000;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.8;
    `}

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    ::before,
    ::after {
      content: " ";
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      border-radius: 10px;
    }

    ::before {
      background-color: #00000000;
      transition: all 0.2s linear;
      transition-property: top, left, right, bottom, border;
      z-index: 2;
      border: 0px solid ${({ theme }) => theme.background.default};
      right: 0px;
    }

    ::after {
      background-color: ${({ theme }) => theme.primary.main};
      transition: bottom 0.4s;
      z-index: 0;
      right: 0px;
    }
  }

  input:focus ~ div {
    ::before {
      left: 6px;
      right: 6px;
      bottom: -2px;
      top: -6px;
      border: 1px solid ${({ theme }) => theme.text.light};
    }

    ::after {
      bottom: -8px;
    }
  }

  input {
    font-size: 0.9em;
    font-weight: 500;
    color: ${({ theme }) => theme.background};
    padding: 1rem 2rem;
    width: 100%;
    z-index: 1;
    position: relative;

    ::placeholder {
      color: ${({ theme }) => theme.background.default};
    }
  }

  ${bp("mobile")`
  input:focus ~ div {
    ::before {
      left: 4px;
      right: 4px;
      bottom: -1px;
      top: -5px;
    }
    
    ::after {
      bottom: -5px;
    }
  }
  `}

  ${props =>
    props.withSubmit &&
    css`
      padding-right: 5em;

      .submit-button {
        position: absolute;
        right: 0;
        z-index: 1;
        margin: 0;
        padding: 0;
        background-color: ${({ theme }) => theme.primary.main};
        width: 5em;
        height: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        cursor: pointer;

        svg {
          width: 1.8em;
          z-index: 1;
        }

        ::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 0.6rem;
          transition: all 0.2s ease-out 0s;
          background-color: rgb(0, 0, 0);
          top: 0px;
          left: 0px;
          opacity: 0.07;
        }

        &:hover:after {
          opacity: 0.15;
        }
      }
    `}
`

interface ContactInputProps {
  withSubmit?: boolean
  ariaLabel?: string
}

const ContactInput: React.FC<
  InputHTMLAttributes<HTMLInputElement> & ContactInputProps
> = ({ withSubmit, ariaLabel, disabled, ...props }) => (
  <ContactInputWrapper {...{ withSubmit, disabled }}>
    <input {...{ ...props, disabled }} />
    <div />

    {withSubmit && (
      <button type="submit" className="submit-button" aria-label={ariaLabel}>
        <SendArrowSVG />
      </button>
    )}
  </ContactInputWrapper>
)

export default ContactInput

import React, { Suspense, useContext, useTransition } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { MenuContext } from "../layouts"
import TransitionLink from "gatsby-plugin-transition-link"
import anime from "animejs"

const FadeLink: React.FC<{
  to?: string
  title?: string
  className?: string
  onClick?: () => void
  children: any
}> = ({ to, children, className, onClick, title }) => {
  const { language } = useI18next()
  const { closeMenu } = useContext(MenuContext)
  const translatedHref = to
    ? (language !== "de" ? `/${language}` : "") +
      (to?.startsWith("/") ? "" : "/") +
      to
    : ""

  const [isPending, startTransition] = useTransition()

  return (
    <TransitionLink
      exit={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 300,
            opacity: [1, 0],
            easing: "linear",
          }),
      }}
      entry={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 300,
            opacity: [0, 1],
            easing: "linear",
          }),
      }}
      onClick={() =>
        startTransition(() => {
          closeMenu?.()
          onClick?.()
        })
      }
      className={className}
      to={translatedHref}
      title={title}
      data-pending={isPending ? "true" : null}
    >
      {children}
    </TransitionLink>
  )
}

export default FadeLink

import React from "react"
import styled from "styled-components"
import { themeOptions } from "../layouts/Styles"
import LineArrow from "../assets/icons/line-arrow.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Collapse } from "react-collapse"
import FadeLink from "./FadeLink"

type SubMenuProps = {
  pages?: {
    title: string
    text: string
    link: string
    order: number
  }[]
  isOpen: boolean
}

const MobileSubMenu: React.FC<SubMenuProps> = ({ pages, isOpen }) => {
  const { t } = useTranslation()

  return (
    <SubMenuContainer>
      <Collapse isOpened={isOpen}>
        {pages
          ?.sort((a, b) => a.order - b.order)
          .map((page, idx) => (
            <Page key={idx}>
              <FadeLink
                className="title"
                to={page.link}
                title={t(page.title) ?? ""}
              >
                {t(page.title)}
                <LineArrow />
              </FadeLink>
              <p>{t(page.text)}</p>
            </Page>
          ))}
      </Collapse>
    </SubMenuContainer>
  )
}

const SubMenuContainer = styled.div`
  display: flex;
  width: 80%;

  .ReactCollapse--collapse {
    transition: ${themeOptions.transition};
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem ${themeOptions.distance};

  > .title {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary.main};
    font-weight: 500;
    gap: 0.8rem;
    padding: 0.2rem 0;

    :hover svg {
      margin-left: 0.3rem;
    }

    svg {
      width: 1rem;
      margin-left: 0;
      transition: ${themeOptions.transition};
    }
  }

  > p {
    font-size: 0.8rem;
  }
`

export default MobileSubMenu

import React from "react"
import styled from "styled-components"
import { themeOptions } from "../layouts/Styles"
import LineArrow from "../assets/icons/line-arrow.svg"
import { useI18next } from "gatsby-plugin-react-i18next"
import FadeLink from "./FadeLink"

const LanguagSwitch: React.FC<{
  onLanguageChanged?: () => void
  mobile?: boolean
}> = ({ onLanguageChanged, mobile = false }) => {
  const { languages, changeLanguage, language } = useI18next()

  return (
    <SubMenuContainer {...{ mobile }}>
      <SubMenuContainerInner {...{ mobile }}>
        {languages.map(
          lang =>
            language !== lang && (
              <Page key={lang} {...{ mobile }}>
                <FadeLink
                  className="title"
                  title={lang.toUpperCase()}
                  onClick={() => {
                    changeLanguage(lang)
                    onLanguageChanged?.()
                  }}
                >
                  {lang.toUpperCase()}
                  <LineArrow />
                </FadeLink>
              </Page>
            )
        )}
      </SubMenuContainerInner>
    </SubMenuContainer>
  )
}

const SubMenuContainer = styled.div<{ mobile?: boolean }>`
  ${({ mobile }) =>
    !mobile &&
    `
      position: absolute;
      top: 5.25rem;
      left: -3.5rem;
      z-index: 1;

  `}
`

const SubMenuContainerInner = styled.div<{ mobile?: boolean }>`
  margin-top: ${({ mobile }) => !mobile && "1.2rem"};
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  gap: 1.5rem;
  background-color: ${({ theme }) => theme.background.default};
  border-radius: 2rem;
  padding: ${themeOptions.distance};

  ${({ mobile }) =>
    !mobile &&
    `
    padding: 1.5rem 3rem;
    width: 11rem;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  `}
`

const Page = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ mobile }) => !mobile && "0 0.5rem"};

  > .title {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary.main};
    font-weight: 500;
    gap: 0.8rem;
    padding: 0.2rem 0;
    cursor: pointer;

    :hover svg {
      margin-left: 0.3rem;
    }

    svg {
      width: 1rem;
      margin-left: 0;
      transition: ${themeOptions.transition};
    }
  }
`

export default LanguagSwitch

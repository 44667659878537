import React from "react"
import styled from "styled-components"
import Button from "../Button"
import { ModalHeader } from "../Modal"
import { bp } from "../../layouts/Styles"
import FadeLink from "../FadeLink"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface CookieNoticeProps {
  onAcceptAll?: () => void
  onGotoSettings?: () => void
  onClose?: () => void
}

const CookieNotice: React.FC<CookieNoticeProps> = ({
  onAcceptAll = () => {},
  onGotoSettings = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <CookieNoticeContainer>
      <ModalHeader
        title={t("Wir verwenden Cookies") ?? ""}
        onClose={() => onClose()}
      />

      <p className="cookie-notice">
        {t(
          "Diese Website benutzt Cookies, die für den technischen Betrieb der Website erforderlich sind und stets gesetzt werden. Andere Cookies, die der Analyse des Nutzungsverhaltens dienen, werden nur mit Ihrer Zustimmung gesetzt. In den"
        )}{" "}
        {t("Einstellungen")}{" "}
        {t(
          "haben Sie die Möglichkeit die Cookies individuell zu akzeptieren oder abzulehnen und mehr über diese zu erfahren. Für die Zukunft können Sie Ihre Einwilligung jederzeit widerrufen."
        )}
      </p>

      <div className="cookie-links">
        <FadeLink to="/datenschutz" onClick={() => onClose()}>
          {t("Datenschutz")}
        </FadeLink>
        <FadeLink to="/impressum" onClick={() => onClose()}>
          {t("Impressum")}
        </FadeLink>
      </div>

      <div className="cookie-buttons">
        <Button
          variant="primary"
          size="medium"
          text={t("Alle Cookies akzeptieren")}
          onClick={() => onAcceptAll()}
          arrow
        />

        <Button
          variant="secondary"
          size="medium"
          text={t("Zu den Einstellungen")}
          onClick={() => onGotoSettings()}
          arrow
        />
      </div>
    </CookieNoticeContainer>
  )
}

const CookieNoticeContainer = styled.div`
  background: ${({ theme }) => theme.background.default};
  font-size: 1.2rem;

  width: 44rem;
  max-width: 100%;
  padding: 2rem;

  h3 {
    font-size: 1.8rem;
  }

  .cookie-notice {
    font-size: 0.8rem;
    line-height: 1.95;
    padding-right: 4rem;
  }

  .cookie-links {
    font-size: 0.9rem;
    margin: 1rem 0 1.5rem;

    a {
      font-weight: 500;
      padding: 0.5rem 2.5rem 0.5rem 0;
    }
  }

  .cookie-buttons {
    display: flex;
    gap: 1rem;

    > div {
      font-size: 1rem;
    }
  }

  ${bp("mobile")`
    font-size: 1.4rem;

    .cookie-notice {
      font-size: 0.85rem;
      line-height: 1.75;
    }

    .cookie-buttons {
      flex-direction: column-reverse;

      > button {
        font-size: 1.3rem;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  `}
`

export default CookieNotice

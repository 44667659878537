import React from "react"
import styled from "styled-components"

const BurgerMenuIcon: React.FC<{ isOpen: boolean; onClick: () => void }> = ({
  isOpen,
  onClick,
}) => {
  return (
    <NavContainer {...{ isOpen, onClick }}>
      <div className="bar" />
    </NavContainer>
  )
}

const NavContainer = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  position: relative;
  width: 2.5rem;
  height: 100%;

  -webkit-tap-highlight-color: transparent;

  .bar,
  .bar:before,
  .bar:after {
    position: absolute;
    background-color: ${({ theme }) => theme.text.light};
    width: 2.5rem;
    height: 2px;
    content: "";
    left: 0;
  }

  .bar {
    top: 50%;
    transform: translateY(-50%);
    transition: background 0.3s cubic-bezier(1, -0.5, 0, 1.6);

    :before {
      bottom: -8px;
      transition: bottom 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
        transform 0.45s cubic-bezier(0.23, 1, 0.32, 1),
        -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    }

    :after {
      top: -8px;
      transition: top 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
        transform 0.45s cubic-bezier(0.23, 1, 0.32, 1),
        -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    }

    ${({ isOpen }) =>
      isOpen &&
      `
        background: hsla(0, 0%, 100%, 0);
        transition: all 0.1s;

        :before {
          bottom: 0;
          transform: rotate(-45deg);
          transition: bottom 0.25s cubic-bezier(0.23, 1, 0.32, 1),
            transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
            -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
        }

        :after {
          top: 0;
          transform: rotate(45deg);
          transition: top 0.25s cubic-bezier(0.23, 1, 0.32, 1),
            transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s,
            -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
        }
    `}
  }
`

export default BurgerMenuIcon

import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import Modal from "./Modal"
import ContactForm, { ThanksModal } from "./modals/ContactForm"

const ContactModal: React.FC<{
  showModal: boolean
  setShowModal: (v: boolean) => void
  setFromElement: (v: MutableRefObject<HTMLElement | null>) => void
}> = ({ showModal, setShowModal, setFromElement }) => {
  const fromElement = useRef<HTMLElement | null>(null)
  const [wasSent, setWasSent] = useState(false)

  useEffect(() => setFromElement(fromElement), [fromElement])
  return (
    <>
      <Modal
        onClose={() => setShowModal(false)}
        isShown={showModal}
        showBackdrop
        fromElement={fromElement}
        alignStart={true}
      >
        <ContactForm
          onClose={() => setShowModal(false)}
          onSent={() => {
            setShowModal(false)
            setWasSent(true)
          }}
        />
      </Modal>

      <ThanksModal isShown={wasSent} onClose={() => setWasSent(false)} />
    </>
  )
}
export default ContactModal

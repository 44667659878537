import React from "react"
import styled from "styled-components"
import { themeOptions } from "../layouts/Styles"
import LineArrow from "../assets/icons/line-arrow.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import FadeLink from "./FadeLink"

type SubMenuProps = {
  isOpen: boolean
  pages?: {
    title: string
    text: string
    link: string
  }[]
  onClick: () => void
}

const SubMenu: React.FC<SubMenuProps> = ({ isOpen, pages, onClick }) => {
  const { t } = useTranslation()

  return (
    <SubMenuContainer {...{ isOpen }}>
      <SubMenuContainerInner columns={pages && pages.length > 4 ? "2" : "1"}>
        {pages?.map((page, idx) => (
          <Page key={idx}>
            <FadeLink
              className="title"
              to={page.link}
              title={t(page.title) ?? ""}
              onClick={onClick}
            >
              {t(page.title)}
              <LineArrow />
            </FadeLink>
            <p>{t(page.text)}</p>
          </Page>
        ))}
      </SubMenuContainerInner>
    </SubMenuContainer>
  )
}

const SubMenuContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 5.25rem;
  left: -3.5rem;
  z-index: 0;
  opacity: 0;
  transition: ${themeOptions.transition};

  ${props =>
    props.isOpen &&
    `
      z-index: 1;
      opacity: 1;
    `};
`

const SubMenuContainerInner = styled.div<{ columns: string }>`
  margin-top: 1.2rem;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, minmax(0px, 1fr));
  gap: 1rem;
  background-color: ${({ theme }) => theme.background.default};
  padding: 1.5rem 3rem;
  border-radius: 2rem;
  width: ${props => (props.columns === "2" ? "45rem" : "25rem")};
  min-width: 24rem;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;

  > .title {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary.main};
    font-weight: 500;
    gap: 0.8rem;
    padding: 0.2rem 0;

    :hover svg {
      margin-left: 0.3rem;
    }

    svg {
      width: 1rem;
      margin-left: 0;
      transition: ${themeOptions.transition};
    }
  }

  > p {
    font-size: 0.8rem;
  }
`

export default SubMenu
